<template>
  <about-us-header />
  <about-back />
  <about-buttons />
  <div class="registry">
    <div class="container">
      <p class="registry-title">{{ $t("documents.reestr") }}</p>
      <p class="registry-sub" v-html="reg[0].description"></p>
      <!-- <p class="registry-sub">Согласно Закона  Республики Казахстан от 18 декабря 2000 года № 126-II «О страховой деятельности», согласно статьи 18-1. Требования к лицам, <br> осуществляющим посредническую деятельность страхового агента, а также к порядку их учета и обучения Пункта 2., Страховая организация обязана вести <br> {{ $t("documents.reestr") }} и размещать его в месте, доступном для обозрения и ознакомления (включая интернет-ресурсы) потребителями страховых услуг. </p> -->
      <div class="registry-box">
          <div class="registry-box__item" v-for="rg in reg" :key="rg">
            <a href="/listOfInsuranceProducts.pdf">
              <img
                class="download-img"
                src="../assets/images/download.svg"
                alt=""
              />
              <p>Перечень страховых продуктов</p>
            </a>
          </div>
          <div class="registry-box__item">
            <a href="/listOfCurrentInsuranceRules.pdf">
              <img
                class="download-img"
                src="../assets/images/download.svg"
                alt=""
              />
              <p>Перечень действующих правил страхования</p>
            </a>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import aboutUsHeader from "./aboutUsHeader.vue";
import AboutBack from "./AboutBack.vue";
import aboutButtons from "./aboutButtons.vue";
import axios from "axios";
import { mapState } from "vuex";

export default {
  components: { aboutUsHeader, AboutBack, aboutButtons },
  data() {
    return {
      reg: [],
    };
  },
  created() {
    axios
      .get("V1/register")
      .then((response) => {
        this.reg = response.data.register;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapState(["cdn"]),
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1441px) and (max-width: 2560px) {
    @content;
  }
}
.registry {
  background: #efefef;
  padding: 85px 0;
  &-title {
    @include fontStyle(Roboto, normal, 500, 28px, 34px, #373737);
    letter-spacing: 0.03em;
    text-transform: uppercase;
  }
  &-sub {
    @include fontStyle(Roboto, normal, 300, 18px, 22px, #373737);
    padding: 30px 0;
  }
  &-box {
    background: #ffffff;
    &__item {
      display: flex;
      align-items: center;
      a {
        display: flex;
        align-items: center;
      }
      img {
        width: 60px;
        height: 40px;
        padding-left: 20px;
      }
      p {
        padding: 23px 0 8px 20px;
        @include fontStyle(Roboto, normal, 300, 20px, 24px, #373737);
        text-decoration: underline;
        margin-bottom: 0;
      }
    }
  }
  @include mobile() {
    padding: 0px;
    &-title {
      display: none;
    }
    &-sub {
      font-size: 14px;
      line-height: 16px;
      padding: 0px;
      padding-top: 10px;
    }
    &-box {
      background: transparent;
      padding-bottom: 30px;
      hr {
        display: none;
      }
      &__item {
        img {
          width: 20px;
          height: 20px;
          margin-right: 5px;
          margin-bottom: 3px;
          padding-left: 0;
        }
        p {
          font-size: 14px;
          line-height: 16px;
          padding: 0px;
          padding-top: 0px;
          text-decoration: none;
        }
        &:first-child {
          p {
            padding-top: 0px;
          }
        }
      }
    }
  }
  @include tablet() {
    padding: 0px;
    &-title {
      display: none;
    }
    &-sub {
      font-size: 14px;
      line-height: 16px;
      padding: 0px;
      padding-top: 10px;
    }
    &-box {
      background: transparent;
      padding-bottom: 30px;
      hr {
        display: none;
      }
      &__item {
        img {
          width: 20px;
          height: 20px;
          margin-right: 5px;
          margin-bottom: 3px;
          padding-left: 0;
        }
        p {
          font-size: 14px;
          line-height: 16px;
          padding: 0px;
          padding-top: 0px;
          text-decoration: none;
        }
        &:first-child {
          p {
            padding-top: 0px;
          }
        }
      }
    }
  }
  @include tablet-1024() {
    padding: 25px 0;
    &-title {
      font-size: 17px;
      line-height: 20px;
    }
    &-sub {
      font-size: 13px;
      line-height: 16px;
      padding: 10px 0;
    }
    &-box {
      background: transparent;
      hr {
        display: none;
      }
      &__item {
        img {
          width: 36px;
          height: 15px;
          margin-right: 5px;
        }
        p {
          font-size: 13px;
          line-height: 17px;
          padding: 0px;
          // padding-top: 13px;
        }
        &:first-child {
          p {
            // padding-top: 15px;
          }
        }
      }
    }
  }
  @include desktop() {
    &-box {
      &__item {
        p {
          padding: 20px 0 20px 20px;
        }
      }
    }
  }
  @include desktop-max() {
    &-box {
      &__item {
        p {
          padding: 20px 0 20px 20px;
        }
      }
    }
  }
}
</style>
