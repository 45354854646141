export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HELLO i18n !!"])},
  "nav": {
    "Bussiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business"])},
    "Individuals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuals"])},
    "ServicesandPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services and payments"])},
    "ServicesandPayments1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "AboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
    "Contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
    "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addresses"])},
    "Vacancies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacancies"])}
  },
  "bussiness": {
    "transport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport insurance"])},
    "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property insurance"])},
    "cargo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargo insurance"])},
    "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liability insurance"])},
    "health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health Insurance"])},
    "temporary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imputed insurance"])}
  },
  "individual": {
    "auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car insurance"])},
    "health": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health insurance"])},
    "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property insurance"])},
    "tourist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tourist insurance"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandatory types of insurance"])},
    "bussiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business insurance"])}
  },
  "service": {
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy a policy"])},
    "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the policy"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment of insurance contracts"])},
    "calc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online сalculator"])},
    "online_settlement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online settlement"])}
  },
  "about": {
    "AboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
    "doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
    "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press Center"])},
    "compl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compliance"])},
    "vac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job openings"])},
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
    "ac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To shareholders"])},
    "dig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital projects"])},
    "quest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])}
  },
  "buttons": {
    "getPresentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get a company presentation"])},
    "moreBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
    "moreBtn1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More on"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply for insurance"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company News"])},
    "allNews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All the main news"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequently Asked Questions"])},
    "vac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job openings"])},
    "vacDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacancies of our company"])},
    "dig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital products and useful applications from Centras Holding"])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show more"])},
    "based": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For all relevant information, please visit our company office or contact our call center"])},
    "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order a callback"])},
    "lmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])},
    "kasko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CASCO"])},
    "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto limit with deductible"])},
    "sakta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAKTA"])},
    "pledged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto pledge"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "polis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue a policy"])},
    "insurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have been insuring health, property and transport since 1997"])},
    "showMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show on map"])},
    "ans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answers to frequently asked questions"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
    "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RATINGS AND Participation in associations"])},
    "form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill out the form"])},
    "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorized appeal"])},
    "anon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonymous appeal"])},
    "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job application"])},
    "speaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Become a speaker"])},
    "sponsor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Become a sponsor"])},
    "hold1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shareholders decisions"])},
    "hold2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register of shareholders"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "dec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decision"])},
    "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
    "decor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registration"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["other questions"])},
    "down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the logo"])},
    "go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go"])},
    "get": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get detailed information"])}
  },
  "offer": {
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information security policy"])}
  },
  "documents": {
    "lis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License"])},
    "fin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial statements"])},
    "reestr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register of Insurance Agents"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate of state registration"])},
    "tar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance rates"])},
    "rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance rules (standard conditions)"])},
    "rec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requisites"])},
    "ruleRu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rules of internal control over insider information in russian"])},
    "ruleKz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rules of internal control over insider information in Kazakh"])},
    "rule1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current rules on compulsory types of insurance"])},
    "rule2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current rules for voluntary types of insurance"])},
    "rule3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voided rules on compulsory and voluntary types of insurance"])},
    "comissionFees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission fees"])}
  },
  "par": {
    "bus1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be safe for your business with an"])},
    "bus2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["individual"])},
    "bus3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["solution to protect against unforeseen risks"])},
    "dev1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The website was developed by"])},
    "dev2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studio Nomad"])},
    "less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Less"])},
    "reviewMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read the review in full"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More detailed"])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose"])},
    "chooseFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File selected"])}
  },
  "modal": {
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save with us"])},
    "individDisc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill out the form and get an individual discount on our products."])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the type of insurance"])},
    "res": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach a resume"])},
    "we": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are happy to help you"])},
    "spec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our experts will help you solve any issue. Just select the products you are interested in and order a callback."])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to know more about our products?"])},
    "present": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will send a presentation about the company and our products within 5 minutes. Just leave your details."])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello! I'm glad to help you!"])},
    "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the products you are interested in and fill in the details. We will contact you as soon as possible."])},
    "check1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree with"])},
    "check2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the personal data processing policy"])},
    "rule1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By clicking on the button, you agree to the terms of"])},
    "rule2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["оpersonal data processing"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a city"])},
    "vacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respond to a vacancy"])}
  },
  "placeholder": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name*"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number*"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail*"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company name*"])},
    "com": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments (not necessary)"])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose one of the options"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The text of the appeal..."])},
    "req": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required field!"])},
    "reqEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrectly entered email!"])}
  },
  "notification": {
    "req": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the required fields, please"])},
    "dontagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have not agreed to the personal data processing policy"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your form has been successfully submitted!"])},
    "reqProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The required fields are not filled in or the type of insurance was not found!"])},
    "successVacancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully created! We will contact you as soon as possible."])},
    "compl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you, we will definitely take your comments into account!"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your form has been sent!"])}
  },
  "product": {
    "med": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medicine"])},
    "kasko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car insurance"])},
    "cargo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargo"])},
    "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property"])},
    "gpo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civil liability to third parties"])},
    "law": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compulsory insurance of legal entities"])},
    "ns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compulsory insurance of employees against accidents"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
    "gpoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will protect your financial interests in case of civil liability"])}
  },
  "medicineRespPul": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional Liability Insurance for Medical Workers"])},
    "p_first": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["AGREEMENT ON PARTICIPATION IN THE UNIFIED INSURANCE POOL FOR THE PROFESSIONAL LIABILITY INSURANCE OF MEDICAL WORKERS Dated October 2,2024"]), _normalize(["Validity: Indefinite"])])},
    "p_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants of the Insurance (Reinsurance) Pool:"])},
    "listOfParticipant": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. JSC \"Insurance Company \"Eurasia\""])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. JSC \"Subsidiary of Halyk Bank of Kazakhstan – Insurance Company \"Halyk\""])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. JSC \"Insurance Company \"Freedom Finance Insurance\""])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. JSC \"Insurance Company \"Jusan Garant\""])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5. JSC \"Insurance Company \"Kazakhmys\""])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6. JSC \"Insurance Company \"NOMAD Insurance\""])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7. JSC \"Insurance Company \"Centras Insurance\""])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8. JSC \"Oil Insurance Company\""])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9. JSC \"Kazakh Corporation for Health and Medical Insurance \"INTERTICH\""])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10. JSC \"Insurance Company \"Sinoasia B&R\""])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11. JSC \"Insurance Company \"Basel\""])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12. JSC \"Insurance Company \"Amanat\""])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13. JSC \"Insurance Company \"ASKO\""])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14. JSC \"Insurance Company \"Victoria\""])}
    ],
    "p_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To enter into an insurance contract, please visit the official online platform of the insurance pool by following the provided link."])},
    "button_instruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instruction"])}
  },
  "medicinePulInstructions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructions"])},
    "terminationContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termination of Contract"])},
    "CreatingContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating a Contract"])},
    "CreatingAdditionalAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating an Additional Agreement"])},
    "CreatingPersonalAccountForIE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating a Personal Account for Individual Entrepreneurs"])},
    "CreatingPersonalAccountLegalEntity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating a Personal Account for Legal Entities"])}
  },
  "carCalculator": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Wear Calculator"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle category"])},
    "motorcar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passenger cars"])},
    "trucks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trucks"])},
    "buses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buses"])},
    "trolleybuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trolleybuses and tram cars"])},
    "trailers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trailers and semi-trailers for trucks"])},
    "car_dacha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trailers for passenger cars and residential vehicles (such as a cottage car)"])},
    "motorcycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motorcycles"])},
    "scooters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scooters, mopeds, scooters"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agricultural tractors, self-propelled agricultural, fire, municipal, loading, construction, road, earthmoving equipment and other equipment based on cars and other self-propelled bases"])},
    "bicycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bicycles"])},
    "vehicleBrand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle brand"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service life, in years"])},
    "period_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle service life (in years), accurate to the decimal place"])},
    "mileage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle mileage, in thousand km"])},
    "mileage_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle mileage in thousands of km (for example, 103,200 km, then you need to enter '103200')"])},
    "procent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle wear, %:"])},
    "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculate"])},
    "wear_vehicle_explanation_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is it?"])},
    "wear_vehicle_explanation_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About vehicle wear and tear"])},
    "wear_vehicle_explanation_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When determining the cost of vehicle repair, wear should be understood as a quantitative measure of the physical aging of a vehicle under the influence of external and internal factors, obtained during operation and characterizing the condition of both the entire vehicle as a whole and its individual elements (assemblies, parts)."])}
  }
}