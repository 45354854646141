<template>
  <div class="container animate__animated animate__fadeIn" style="animation-duration: 3s;">
    <div class="buttons m_none">
      <div class="buttons-top">
        <router-link to="/LicenseAbout"
          ><button @click="isActiveButton" :class="{ active: isActive }">
            {{ $t("documents.lis") }}
          </button></router-link
        >
        <router-link to="/financeAccordion"
          ><button>{{ $t("documents.fin") }}</button></router-link
        >
        <router-link to="/registryAbout"
          ><button>{{ $t("documents.reestr") }}</button></router-link
        >
        <router-link to="/referenceAbout"
          ><button>{{ $t("documents.reg") }}</button></router-link
        >
         <router-link to="/insuranceDocumentAbout"
          ><button>{{ $t("documents.insurance_document") }}</button></router-link
        >
      </div>
      <div class="buttons-bottom">
        <!-- <a href="/tarif.xlsx" download -->
        <a :href="cdn + link" download

          ><button>{{ $t("documents.tar") }}</button></a
        >
        <router-link to="/rulesAbout"
          ><button>{{ $t("documents.rule") }}</button></router-link
        >
        <router-link to="/requisitesAbout"
          ><button>{{ $t("documents.rec") }}</button></router-link
        >
        <a href="/remuneration.xlsx" download
          ><button>{{ $t("documents.comissionFees") }}</button></a
        >
        <!-- <a :href="cdn + link"><button>Гос регистрация</button></a> -->
      </div>
    </div>
  </div>
  <div class="select d_none animate__animated animate__fadeIn" style="animation-duration: 3s;">
    <div class="container">
      <div class="select-choose">{{ $t("par.choose") }}..</div>
      <select class="select-asdasd" v-model="selected" @change="onChange">
        <!-- <option selected value="documentsAbout">{{ $t("par.choose") }}..</option> -->
        <option v-for="(item,id) in option" :key="id" :value="item.value">{{ this.$t(item.name) }}</option>
        <!-- <option v-for="item in option" :key="item" :value="item.value">asdasd</option> -->
      </select>
    </div>
    <div></div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  data() {
    const selected = sessionStorage.getItem("mobLocation") || "null";
    return {
      link: [],
      selected: selected,
      isActive: false,
      option: [
        {
          value: "LicenseAbout",
          name: "documents.lis",
        },
        {
          value: "financeMobile",
          name: "documents.fin",
        },
        {
          value: "registryAbout",
          name: "documents.reestr",
        },
        {
          value: "referenceAbout",
          name: "documents.reg",
        },
        {
          value: "tarifs",
          name: "documents.tar",
        },
        {
          value: "rulesMobile",
          name: "documents.rule",
        },
        {
          value: "requisitesAbout",
          name: "documents.rec",
        },
        {
          value: "comissionFees",
          name: "documents.comissionFees",
        }
      ]
    };
  },
  created() {
    axios
      .get("V1/rates")
      .then((response) => {
        this.link = response.data.rates[0].doc;
      })
      .catch((e) => {
        this.errors.push(e);
      });
  },
  computed: {
    ...mapState(["cdn"]),
  },
  methods: {
    onChange(event) {
      location.href = event.target.value;
      sessionStorage.setItem("mobLocation", event.target.value);
    },
    isActiveButton: function () {
      this.isActive = !this.isActive;
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}
@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}
@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1440px) {
    @content;
  }
}
.select {
  background-color: #efefef;
  padding-top: 30px;
  padding-bottom: 20px;
  &-asdasd {
    background: #ffffff;
    border-radius: 3px;
    border: none;
    width: 100%;
    height: 40px;
    @include fontStyle(Roboto, normal, 500, 15px, 16px, #373737);
    padding: 8px 0 8px 20px;

    &__option {
    }
  }
  @include mobile() {
    padding-top: 35px;
    padding-bottom: 10px;
    &-asdasd {
      appearance: none;
      background-image: url(@/assets/images/arrow1.svg);
      background-position: calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
      background-size: 15px 9px, 10px 10px, 1px 1.5em;
      background-repeat: no-repeat;
    }
  }
  @include tablet() {
    padding-top: 35px;
    padding-bottom: 10px;
    &-asdasd {
      height: 40px;
      appearance: none;
      background-image: url(@/assets/images/arrow1.svg);
      background-position: calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
      background-size: 15px 9px, 10px 10px, 1px 1.5em;
      background-repeat: no-repeat;
    }
  }
  @include tablet-1024() {
    padding-top: 35px;
    padding-bottom: 8px;
  }
  &-choose {
    @include fontStyle(Roboto, normal, 500, 15px, 16px, #373737);
    padding-bottom: 5px;
  }
}
.buttons {
  margin: 85px 0 50px 0;
  &-top,
  &-bottom {
    display: flex;
    button {
      border: 1px solid #c4c4c4;
      border-radius: 10px;
      padding: 16px 28px;
      background-color: transparent;
      @include fontStyle(Roboto, normal, 300, 24px, 29px, #373737);
      margin: 0 20px 35px 0;
      &:hover,
      &:focus {
        background: linear-gradient(
          90deg,
          #ff4466 0%,
          #be31ce 49.97%,
          #3641ff 100%
        );
        color: #ffffff;
      }
    }
  }
  @include mobile() {
    margin: 20px 0 20px 0;
    &-top,
    &-bottom {
      flex-wrap: wrap;
      button {
        font-size: 9px;
        line-height: 12px;
        width: 100px;
        height: 40px;
        padding: 1px 8px;
        margin-bottom: 15px;
      }
    }
  }
  @include tablet() {
    margin: 20px 0 20px 0;
    &-top,
    &-bottom {
      flex-wrap: wrap;
      align-items: end;
      button {
        font-size: 9px;
        line-height: 12px;
        width: 90px;
        height: 40px;
        padding: 1px 8px;
        margin-bottom: 15px;
      }
    }
  }
  @include tablet-1024() {
    margin: 20px 0 20px 0;
    &-top,
    &-bottom {
      flex-wrap: wrap;
      button {
        font-size: 9px;
        line-height: 12px;
        width: 100px;
        height: 40px;
        padding: 1px 8px;
        margin-bottom: 15px;
      }
    }
  }
  @include desktop() {
    margin: 20px 0 20px 0;
    &-top,
    &-bottom {
      flex-wrap: wrap;
      button {
        font-size: 9px;
        line-height: 12px;
        width: 100px;
        height: 40px;
        padding: 1px 8px;
        margin-bottom: 15px;
      }
    }
  }
}
</style>
