<template>
    <div>
        <head-bottom />
        <div class="med">
            <div class="container">
                <div class="med-cards">
                    <div class="med-cards-left">
                        <p class="med-cards-left__title">{{ $t("medicineRespPul.title") }}</p>
                        <!-- <p class="med-cards-left__title">СТРАХОВАНИЕ ОТВЕТСТВЕННОСТИ <br> МЕДИЦИНСКИХ РАБОТНИКОВ</p> -->
                        <p class="med-cards-left__sub">{{ $t("medicineRespPul.p_first") }}</p>
                        <p class="med-cards-left__sub">{{ $t("medicineRespPul.p_two") }}</p>
                         <p v-for="id in 14" :key="id" >{{ $t(`medicineRespPul.listOfParticipant[${id - 1}]`) }}</p>
                        <p class="med-cards-left__sub">{{ $t("medicineRespPul.p_three") }} </p>
                        <div class="med-cards-left__btn">
                            <a href="https://medpul.kz/"><button>medpul.kz</button></a>
                            <a href="/medicinePulInstructions"><button>{{ $t("medicineRespPul.button_instruction") }}</button></a>
                        </div>
                    </div>
                    <div class="med-cards-right">
                        <img v-if="lang == 'ru'" src="../assets/images/med_ins_ru.png" />
                        <img v-if="lang == 'en'" src="../assets/images/med_ins_eng.png" />
                        <img v-if="lang == 'kz'" src="../assets/images/med_ins_kz.png" />
                    </div>
                </div>
            </div>
        </div>
      
        <!-- <timeBtn/> -->
  
        <!-- <div class="slider-container">
            <slider-main-1/>
        </div> -->
        <slider-main-1 class="m_none" />
        <slider-main-mobile class="d_none" />
    </div>
</template>

<script>
import Question from "../components/Question.vue";
import HeadBottom from "../components/HeadBottom.vue";
import SliderMain1 from "../components/SliderMain1-1.vue";
import accordion from "../components/accordion.vue";
import timeBtn from "../components/timeBtn.vue";
import SliderMainMobile from "@/components/SliderMainMobile.vue";
import PolisButton from "@/components/PolisButton.vue";
import axios from "axios";
import { mapState } from "vuex";
export default {
    components: {
        HeadBottom,
        Question,
        SliderMain1,
        SliderMainMobile,
        accordion,
        PolisButton,
        timeBtn,
    },
    data() {
        return {
            p_name: "Страхование ответственности медицинских работников",
            med: [],
            lang: localStorage.getItem('lang') || 'ru'
        };
    },
    created() {
        axios
            .get("V1/business/liabity")
            .then((response) => {
                this.med = response.data.data[5].sub_cats;
                this.timeTitle = response.data.data[5].sub_cats[3].title;
                this.timeDesc = response.data.data[5].sub_cats[3].desc;
            })
            .catch((e) => {
                this.errors.push(e);
            });
    },
    computed: {
        ...mapState(["cdn"]),
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/timeBtn.scss";

@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
    font-family: $family;
    font-style: normal;
    font-weight: $weight;
    font-size: $size;
    line-height: $lheight;
    color: $color;
}

@mixin mobile {
    @media (min-width: 320px) and (max-width: 425px) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: 425px) and (max-width: 768px) {
        @content;
    }
}

@mixin tablet-1024 {
    @media (min-width: 768px) and (max-width: 1024px) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: 1024px) and (max-width: 1440px) {
        @content;
    }
}

@mixin desktop-max {
    @media (min-width: 1620px) and (max-width: 2560px) {
        @content;
    }
}

.container {
    padding-left: 0;
    padding-right: 0;
}

.par {
    display: flex;
    align-items: baseline;
}

.med {
    background: #efefef;
    padding-top: 50px;
    padding-bottom: 30px;

    &-cards {
        display: flex;
        justify-content: space-between;

        &-left {
            &__title {
                @include fontStyle(Roboto, normal, 500, 36px, 43px, transparent);
                letter-spacing: 0.03em;
                text-transform: uppercase;
                background: linear-gradient(90deg,
                        #ff143f 0%,
                        #c600dd 49.97%,
                        #1b27ff 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
            }

            &__sub {
                @include fontStyle(Roboto, normal, 400, 24px, 36px, #373737);
                text-transform: uppercase;
                padding-top: 20px;
                padding-bottom: 20px;
            }

            &__btn,
            &__btn-mob {
                display: flex;
                gap: 30px;
                button {
                    background: #ffffff;
                    border-radius: 15px;
                    width: 200px;
                    height: 70px;
                    border: none;
                    color: #373737;
                    @include fontStyle(Roboto, normal, 600, 18px, 25px, #373737);

                    &:hover {
                        background: linear-gradient(90deg,
                                #ff4466 0%,
                                #be31ce 49.97%,
                                #3641ff 100%);
                        color: #ffffff;
                    }
                }
            }
        }

        &-right {
            width: 37%;

            img {
                width: 100%;
                min-width: 300px;
            }
        }
    }

    @include mobile() {
        padding-top: 20px;
        padding-bottom: 20px;

        &-cards {
            flex-wrap: wrap;

            &-left {
                margin: 0 auto;

                &__title {
                    width: 100%;
                    font-size: 14px;
                    line-height: 17px;
                    // text-align: center;
                }

                &__sub {
                    font-size: 12px;
                    line-height: 14px;
                    padding-top: 0;
                    padding-bottom: 0;
                }

                &__btn-mob {
                    text-align: center;

                    button {
                        border-radius: 3px;
                        width: 96px;
                        height: 28px;
                        font-size: 9px;
                    }
                }
            }

            &-right {
                width: 100%;
                text-align: center;

                img {
                    min-width: 200px;
                    margin-top: 10px;
                    width: 50%;
                }
            }
        }
    }

    @include tablet() {
        &-cards {
            flex-wrap: wrap;

            &-left {
                margin: 0 auto;

                &__title {
                    // text-align: center;
                    width: 100%;
                    font-size: 22px;
                    line-height: 24px;
                }

                &__sub {
                    font-size: 16px;
                    line-height: 22px;
                    padding-top: 0;
                    padding-bottom: 0;
                }

                &__btn-mob {
                    text-align: center;

                    button {
                        border-radius: 3px;
                        width: 116px;
                        height: 38px;
                        font-size: 10px;
                    }
                }
            }

            &-right {
                width: 100%;
                text-align: center;

                img {
                    margin-top: 10px;
                    width: 50%;
                }
            }
        }
    }

    @include tablet-1024() {
        &-cards {
            &-right {
                img {
                    min-width: 200px;
                    width: 50%;
                }
            }

            &-left {
                width: 70%;

                &__title {
                    font-size: 18px;
                    line-height: 20px;
                }

                &__sub {
                    font-size: 12px;
                    line-height: 18px;
                }

                &__btn {
                    button {
                        width: 170px;
                        height: 50px;
                        border-radius: 7px;
                        font-size: 14px;
                    }
                }
            }
        }
    }

    @include desktop() {
        &-cards {
            &-left {
                width: 70%;

                &__title {
                    font-size: 24px;
                    line-height: 28px;
                }

                &__sub {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }
}

.object {
    padding-top: 85px;
    padding-bottom: 55px;

    @include mobile() {
        padding-top: 25px;
        padding-bottom: 20px;
    }

    @include tablet() {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    @include tablet-1024() {
        padding-top: 40px;
        padding-bottom: 25px;
    }
}

.risk {
    &-par {
        &__ul {
            margin-left: 20px;

            li {
                @include fontStyle(Roboto, normal, 100, 18px, 22px, #373737);
            }
        }
    }
}

.object,
.risk {
    &-title {
        @include fontStyle(Roboto, normal, 500, 28px, 34px, #373737);
        text-transform: uppercase;
    }

    &-par {
        @include fontStyle(Roboto, normal, 300, 18px, 22px, #373737);

        span {
            @include fontStyle(Roboto, normal, 900, 24px, 33px, #ffffff);
            letter-spacing: 0.03em;
            background: -webkit-linear-gradient(180deg,
                    #1b27ff 39%,
                    #c600dd 44%,
                    #ff143f 69%);
            -webkit-background-clip: text;
            -webkit-text-stroke: 2px transparent;
            color: #ffffff;
            padding-right: 5px;
            padding-left: 0px;
        }
    }

    span {
        @include fontStyle(Roboto, normal, 900, 24px, 33px, #ffffff);
        letter-spacing: 0.03em;
        background: -webkit-linear-gradient(180deg,
                #1b27ff 39%,
                #c600dd 44%,
                #ff143f 69%);
        -webkit-background-clip: text;
        -webkit-text-stroke: 2px transparent;
        color: #ffffff;
        padding-right: 5px;
        padding-left: 0px;
    }

    @include mobile() {
        &-title {
            font-size: 14px;
            text-transform: uppercase;
            letter-spacing: 0.03em;
            margin-bottom: 0;
        }

        &-par {
            font-size: 12px;
            line-height: 14px;
            margin-top: -10px;

            span {
                font-size: 12px;
                line-height: 14px;
                font-weight: 700;
                -webkit-text-stroke: 1px transparent;
            }
        }

        span {
            font-size: 12px;
            line-height: 14px;
            font-weight: 700;
            -webkit-text-stroke: 1px transparent;
        }
    }

    @include tablet() {
        &-title {
            font-size: 16px;
            text-transform: uppercase;
            letter-spacing: 0.03em;
        }

        &-par {
            font-size: 12px;
            line-height: 14px;
            margin-top: -10px;

            span {
                font-size: 14px;
                line-height: 12px;
                font-weight: 700;
                -webkit-text-stroke: 1px transparent;
            }
        }

        span {
            font-size: 14px;
            line-height: 12px;
            font-weight: 700;
            -webkit-text-stroke: 1px transparent;
        }
    }

    @include tablet-1024() {
        &-title {
            font-size: 20px;
            text-transform: uppercase;
            letter-spacing: 0.03em;
        }

        &-par {
            font-size: 14px;
            line-height: 14px;

            span {
                font-size: 14px;
                line-height: 12px;
                font-weight: 700;
                -webkit-text-stroke: 1px transparent;
            }
        }

        span {
            font-size: 14px;
            line-height: 12px;
            font-weight: 700;
            -webkit-text-stroke: 1px transparent;
        }
    }
}
</style>
