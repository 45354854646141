<template>
    <head-bottom />
    <section class="wear-vehicle">
        <div class="container">
            <a-row class="wear-vehicle__wrapper" justify="space-between" type="flex">
                <a-col :md="11" :xs="24">
                    <a-form class="wear-vehicle__form" ref="ruleForm" :model="form" :rules="rules">
                        <h3>{{ $t('carCalculator.title') }}</h3>
                        <a-form-item name="category">
                            <a-select :placeholder="$t('carCalculator.category')" class="wear-vehicle__select" dropdown-class-name="wear-vehicle__select-dropdown" @change="categoryChange">
                                <a-select-option value="1">
                                    {{ $t('carCalculator.motorcar') }}
                                </a-select-option>
                                <a-select-option value="2" avalue="0.077" bvalue="0.0023">
                                    {{ $t('carCalculator.trucks') }}
                                </a-select-option>
                                <a-select-option value="3" avalue="0.113" bvalue="0.0008">
                                    {{ $t('carCalculator.buses') }}
                                </a-select-option>
                                <a-select-option value="4" avalue="0.098" bvalue="0.0008">
                                    {{ $t('carCalculator.trolleybuses') }}
                                </a-select-option>
                                <a-select-option value="5" avalue="0.09" bvalue="0">
                                    {{ $t('carCalculator.trailers') }}
                                </a-select-option>
                                <a-select-option value="6" avalue="0.06" bvalue="0">
                                    {{ $t('carCalculator.car_dacha') }}
                                </a-select-option>
                                <a-select-option value="7" avalue="0.07" bvalue="0">
                                    {{ $t('carCalculator.motorcycles') }}
                                </a-select-option>
                                <a-select-option value="8" avalue="0.09" bvalue="0">
                                    {{ $t('carCalculator.scooters') }}
                                </a-select-option>
                                <a-select-option value="9" avalue="0.15" bvalue="0">
                                    {{ $t('carCalculator.other') }}
                                </a-select-option>
                                <a-select-option value="10" avalue="0.04" bvalue="0">
                                    {{ $t('carCalculator.bicycles') }}
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item v-if="form.category === '1'" name="brand">
                            <a-select class="wear-vehicle__select" dropdown-class-name="wear-vehicle__select-dropdown" :placeholder="$t('carCalculator.vehicleBrand')" @change="brandChange">
                                <a-select-option value="1" avalue="0.057" bvalue="0.0030">
                                    ВАЗ (Lada), ГАЗ, ЗАЗ
                                </a-select-option>
                                <a-select-option value="2" avalue="0.057" bvalue="0.0029">
                                    Brilliance, BYD, Chery, Derways, FAW, Geely, Great Wall, Hafei, Haima, Lifan, Luxgen, Xin Kai
                                </a-select-option>
                                <a-select-option value="3" avalue="0.042" bvalue="0.0023">
                                    Aston Martin, Bentley, Bugatti, Ferrari, Jaguar, Maserati, Porsche Audi, BMW, Mercedes-Benz, Mini, Rover Alfa Romeo, Citroen, Fiat, Ford, Opel, Peugeot, Renault, Saab, SEAT, Skoda, Volkswagen, Volvo
                                </a-select-option>
                                <a-select-option value="4" avalue="0.045" bvalue="0.0024">
                                    Acura, Buick, Cadillac, Chevrolet, Chrysler, Dodge, Hummer, Infiniti, Jeep, Lexus, Lincoln, Mercury, Pontiac
                                </a-select-option>
                                <a-select-option value="5" avalue="0.052" bvalue="0.0026">
                                    Hyundai, Kia, Ssang Yong, Daewoo
                                </a-select-option>
                                <a-select-option value="6" avalue="0.049" bvalue="0.0025">
                                    Daihatsu, Honda, Isuzu, Mazda, Mitsubishi, Nissan, Subaru, Suzuki, Toyota
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item name="period">
                            <a-input
                                v-model="form.period"
                                :placeholder="$t('carCalculator.period')"
                                mask="^[0-9]{1,6}$"
                                is-regex
                                @change="periodChange"
                            />
                            <span class="wear-vehicle__note">{{ $t('carCalculator.period_note') }}</span>
                        </a-form-item>
                        <a-form-item  name="mileage">
                            <a-input
                                v-model="form.mileage"
                                :placeholder="$t('carCalculator.mileage')"
                                mask="^[0-9]{1,6}$"
                                is-regex
                                @change="mileageChange"
                            />
                            <span class="wear-vehicle__note">{{ $t('carCalculator.mileage_note') }}</span>
                        </a-form-item>
                        <div v-if="wear" class="wear-vehicle__procent">
                            {{ $t('carCalculator.procent') }}
                            <br/>
                            <span class="wear-vehicle__procent-total">{{ wear }} %</span>
                        </div>
                        <a-button class="wear-vehicle__calculate button-default button-gradient" @click="submitButtonClick">{{ $t('carCalculator.calculate') }}</a-button>
                    </a-form>
                </a-col>
                	<a-col :lg="13" :md="12" :sm="24" class="wear-vehicle__explanation">
					<h3 class="wear-vehicle__explanation-title">{{ $t('carCalculator.wear_vehicle_explanation_title') }}</h3>
					<h4 class="wear-vehicle__explanation-subtitle">{{ $t('carCalculator.wear_vehicle_explanation_subtitle') }}</h4>
					<p class="wear-vehicle__explanation-descr">
						{{ $t('carCalculator.wear_vehicle_explanation_desc') }}
					</p>
				</a-col>
             
            </a-row>
        </div>
    </section>
</template>
<script>
import {
	Button,
	Card,
	Col,
	Row,
	Form,
	Select,
    Input
} from 'ant-design-vue';
import Question from "../components/Question.vue";
import HeadBottom from "../components/HeadBottom.vue";
import SliderMain1 from "../components/SliderMain1-1.vue";
import accordion from "../components/accordion.vue";
import timeBtn from "../components/timeBtn.vue";
import SliderMainMobile from "@/components/SliderMainMobile.vue";
import PolisButton from "@/components/PolisButton.vue";
import axios from "axios";
import { mapState } from "vuex";
export default {
    components: {
        HeadBottom,
        Question,
        SliderMain1,
        SliderMainMobile,
        accordion,
        PolisButton,
        timeBtn,
        ARow: Row,
		ACol: Col,
        AButton: Button,
		AForm: Form,
        AFormItem: Form.Item,
		ASelect: Select,
        AInput: Input,
        ASelectOption: Select.Option,
    },
    data() {
        return {
            p_name: "Калькулятор износа ТС",
            med: [],
            lang: localStorage.getItem('lang') || 'ru',
            rules: {
				category: [{ required: true, message: 'Выберите категорию', trigger: 'change' }],
				brand: [{ required: true, message: 'Выберите марку', trigger: 'change' }],
                period: [{ required: true, message: 'Введите период', trigger: 'change' }],
                mileage: [{ required: true, message: 'Введите пробег', trigger: 'change' }],
			},
			form: {
				category: undefined,
				brand: undefined,
                period: undefined,
			    mileage: undefined,
			},
            
            aValue: '',
			bValue: '',
            e: 2.72,
			q: null,
			wear: null,
        };
    },
    methods: {
        categoryChange(value, option) {
			this.form.category = value;
            console.log(option);
            
			this.aValue = option.avalue;
			this.bValue = option.bvalue;
		},
        brandChange(value, option) {
			this.form.brand = value;
            console.log(option);
            
			this.aValue = option.avalue;
			this.bValue = option.bvalue;
		},
        periodChange(e) {
			this.form.period = e.target.value;
		},
        mileageChange(e) {
			this.form.mileage = e.target.value;
		},
        /**
         * Функция, зависящая от возраста и фактического пробега
         */
		calculateQ() {
            console.log(this.aValue);
            console.log(this.form.period);
            console.log(this.bValue);
            console.log(this.form.mileage);
			return this.aValue * +this.form.period.replace(',', '.') + this.bValue * (+this.form.mileage / 1000);
		},

        /**
         * Расчет величины физического износа
         */
		calculateWear() {
            
			this.wear = (100 * (1 - this.e ** -this.calculateQ())).toFixed(2);
		},
		submitButtonClick() {
            this.$refs.ruleForm.validateFields().then(values => {
               this.calculateWear();
            }).catch(error => {
                console.log(error);
                
            })
		},
    },
    created() {
        axios
            .get("V1/business/liabity")
            .then((response) => {
                this.med = response.data.data[5].sub_cats;
                this.timeTitle = response.data.data[5].sub_cats[3].title;
                this.timeDesc = response.data.data[5].sub_cats[3].desc;
            })
            .catch((e) => {
                this.errors.push(e);
            });
    },
    computed: {
        ...mapState(["cdn"]),
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/timeBtn.scss";

@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
    font-family: $family;
    font-style: normal;
    font-weight: $weight;
    font-size: $size;
    line-height: $lheight;
    color: $color;
}

@mixin mobile {
    @media (min-width: 320px) and (max-width: 425px) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: 425px) and (max-width: 768px) {
        @content;
    }
}

@mixin tablet-1024 {
    @media (min-width: 768px) and (max-width: 1024px) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: 1024px) and (max-width: 1440px) {
        @content;
    }
}

@mixin desktop-max {
    @media (min-width: 1620px) and (max-width: 2560px) {
        @content;
    }
}

.container {
    padding-left: 0;
    padding-right: 0;
}

.par {
    display: flex;
    align-items: baseline;
}

.med {
    background: #efefef;
    padding-top: 50px;
    padding-bottom: 30px;

    &-cards {
        display: flex;
        justify-content: space-between;

        &-left {
            &__title {
                @include fontStyle(Roboto, normal, 500, 36px, 43px, transparent);
                letter-spacing: 0.03em;
                text-transform: uppercase;
                background: linear-gradient(90deg,
                        #ff143f 0%,
                        #c600dd 49.97%,
                        #1b27ff 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
            }

            &__sub {
                @include fontStyle(Roboto, normal, 400, 24px, 36px, #373737);
                text-transform: uppercase;
                padding-top: 20px;
                padding-bottom: 20px;
            }

            &__btn,
            &__btn-mob {
                display: flex;
                gap: 30px;
                button {
                    background: #ffffff;
                    border-radius: 15px;
                    width: 200px;
                    height: 70px;
                    border: none;
                    color: #373737;
                    @include fontStyle(Roboto, normal, 600, 18px, 25px, #373737);

                    &:hover {
                        background: linear-gradient(90deg,
                                #ff4466 0%,
                                #be31ce 49.97%,
                                #3641ff 100%);
                        color: #ffffff;
                    }
                }
            }
        }

        &-right {
            width: 37%;

            img {
                width: 100%;
                min-width: 300px;
            }
        }
    }

    @include mobile() {
        padding-top: 20px;
        padding-bottom: 20px;

        &-cards {
            flex-wrap: wrap;

            &-left {
                margin: 0 auto;

                &__title {
                    width: 100%;
                    font-size: 14px;
                    line-height: 17px;
                    // text-align: center;
                }

                &__sub {
                    font-size: 12px;
                    line-height: 14px;
                    padding-top: 0;
                    padding-bottom: 0;
                }

                &__btn-mob {
                    text-align: center;

                    button {
                        border-radius: 3px;
                        width: 96px;
                        height: 28px;
                        font-size: 9px;
                    }
                }
            }

            &-right {
                width: 100%;
                text-align: center;

                img {
                    min-width: 200px;
                    margin-top: 10px;
                    width: 50%;
                }
            }
        }
    }

    @include tablet() {
        &-cards {
            flex-wrap: wrap;

            &-left {
                margin: 0 auto;

                &__title {
                    // text-align: center;
                    width: 100%;
                    font-size: 22px;
                    line-height: 24px;
                }

                &__sub {
                    font-size: 16px;
                    line-height: 22px;
                    padding-top: 0;
                    padding-bottom: 0;
                }

                &__btn-mob {
                    text-align: center;

                    button {
                        border-radius: 3px;
                        width: 116px;
                        height: 38px;
                        font-size: 10px;
                    }
                }
            }

            &-right {
                width: 100%;
                text-align: center;

                img {
                    margin-top: 10px;
                    width: 50%;
                }
            }
        }
    }

    @include tablet-1024() {
        &-cards {
            &-right {
                img {
                    min-width: 200px;
                    width: 50%;
                }
            }

            &-left {
                width: 70%;

                &__title {
                    font-size: 18px;
                    line-height: 20px;
                }

                &__sub {
                    font-size: 12px;
                    line-height: 18px;
                }

                &__btn {
                    button {
                        width: 170px;
                        height: 50px;
                        border-radius: 7px;
                        font-size: 14px;
                    }
                }
            }
        }
    }

    @include desktop() {
        &-cards {
            &-left {
                width: 70%;

                &__title {
                    font-size: 24px;
                    line-height: 28px;
                }

                &__sub {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }
}
.wear-vehicle {
    &__form{
       padding: 16px 18px 17px 19px;
        background: #fff;
        box-shadow: 0 0 20px rgba(0,0,0,.2);
        border-radius: 16px;
        margin: 30px 0;
    };
    &__explanation{
        margin: 30px 0;
        padding: 16px 18px 17px 19px;
         h3{
            background:-webkit-linear-gradient(180deg,
                        #1b27ff 39%,
                        #c600dd 44%,
                        #ff143f 69%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
        }
    };
    &__procent{
        font-weight: 500;
        font-size: 16px;
        span{
            display: block;
            margin: 15px 0;
        }
    }

}
.object {
    padding-top: 85px;
    padding-bottom: 55px;

    @include mobile() {
        padding-top: 25px;
        padding-bottom: 20px;
    }

    @include tablet() {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    @include tablet-1024() {
        padding-top: 40px;
        padding-bottom: 25px;
    }
}

.risk {
    &-par {
        &__ul {
            margin-left: 20px;

            li {
                @include fontStyle(Roboto, normal, 100, 18px, 22px, #373737);
            }
        }
    }
}

.object,
.risk {
    &-title {
        @include fontStyle(Roboto, normal, 500, 28px, 34px, #373737);
        text-transform: uppercase;
    }

    &-par {
        @include fontStyle(Roboto, normal, 300, 18px, 22px, #373737);

        span {
            @include fontStyle(Roboto, normal, 900, 24px, 33px, #ffffff);
            letter-spacing: 0.03em;
            background: -webkit-linear-gradient(180deg,
                    #1b27ff 39%,
                    #c600dd 44%,
                    #ff143f 69%);
            -webkit-background-clip: text;
            -webkit-text-stroke: 2px transparent;
            color: #ffffff;
            padding-right: 5px;
            padding-left: 0px;
        }
    }

    span {
        @include fontStyle(Roboto, normal, 900, 24px, 33px, #ffffff);
        letter-spacing: 0.03em;
        background: -webkit-linear-gradient(180deg,
                #1b27ff 39%,
                #c600dd 44%,
                #ff143f 69%);
        -webkit-background-clip: text;
        -webkit-text-stroke: 2px transparent;
        color: #ffffff;
        padding-right: 5px;
        padding-left: 0px;
    }

    @include mobile() {
        &-title {
            font-size: 14px;
            text-transform: uppercase;
            letter-spacing: 0.03em;
            margin-bottom: 0;
        }

        &-par {
            font-size: 12px;
            line-height: 14px;
            margin-top: -10px;

            span {
                font-size: 12px;
                line-height: 14px;
                font-weight: 700;
                -webkit-text-stroke: 1px transparent;
            }
        }

        span {
            font-size: 12px;
            line-height: 14px;
            font-weight: 700;
            -webkit-text-stroke: 1px transparent;
        }
    }

    @include tablet() {
        &-title {
            font-size: 16px;
            text-transform: uppercase;
            letter-spacing: 0.03em;
        }

        &-par {
            font-size: 12px;
            line-height: 14px;
            margin-top: -10px;

            span {
                font-size: 14px;
                line-height: 12px;
                font-weight: 700;
                -webkit-text-stroke: 1px transparent;
            }
        }

        span {
            font-size: 14px;
            line-height: 12px;
            font-weight: 700;
            -webkit-text-stroke: 1px transparent;
        }
    }

    @include tablet-1024() {
        &-title {
            font-size: 20px;
            text-transform: uppercase;
            letter-spacing: 0.03em;
        }

        &-par {
            font-size: 14px;
            line-height: 14px;

            span {
                font-size: 14px;
                line-height: 12px;
                font-weight: 700;
                -webkit-text-stroke: 1px transparent;
            }
        }

        span {
            font-size: 14px;
            line-height: 12px;
            font-weight: 700;
            -webkit-text-stroke: 1px transparent;
        }
    }
}
</style>
