import { createApp } from 'vue'
import App from './App.vue'
import VueRouter from './router'
import i18n from './i18n'
import axios from 'axios'
import VueNumber from 'vue-number-animation'
import VLazyImage from "v-lazy-image";
//VUELIDATE
import Vuelidate from 'vuelidate'
import 'animate.css';
import './assets/style/main.scss'
import AOS from "aos";
import "aos/dist/aos.css"
import Vue from 'vue'
import VueAnimate from 'vue-animate-scroll'
import Toast from "vue-toastification";
import Maska from 'maska'
import store from './store'
import VueTheMask from 'vue-the-mask'
import moment from 'moment'
import VueGtag from "vue-gtag";


import '@/letsee/letsee'
import '@/letsee/letsee.css'

// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
// import Embed from 'v-video-embed'
AOS.init();
const lang = localStorage.getItem('lang') || 'ru';
axios.defaults.baseURL = 'https://cic.kz/api/';
axios.defaults.params = {
  'lang': lang,
};


const options = {
  position: "top-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: false,
  icon: true,
  rtl: false,
  maxToasts: 1,
};
const app = createApp(App)
app.use(i18n)
app.use(VueRouter)
app.use(VueNumber)
app.use(VueAnimate)
app.use(VLazyImage)
app.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 1,
});
// app.use(Toast,options)

//Vuelidate
app.config.errorHandler = () => null;
app.config.warnHandler = () => null;
app.use(Vuelidate)
app.use(store)
app.use(moment)
app.use(VueTheMask)
app.use(VueGtag, {
  config: { id: "G-DZ84BLE6XE" }
})
app.mount('#app')
